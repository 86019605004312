@import 'src/utils/utils';

.mesh {
  @include zero;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000px;
  z-index: -1;
  pointer-events: none;

  @media(min-width: $lg) {
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/mesh.png');
    background-position: center;
    background-size: cover;
    content: '';
  }

  &:after {
    position: absolute;
    width: 100%;
    height: 600px;
    background: linear-gradient(0deg, #F1F1F1 28.76%, rgba(241, 241, 241, 0.00) 100%);
    bottom: 0;
    content: '';
  }
}